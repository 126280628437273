import { Dayjs } from 'dayjs';
import {
  ModeloComercial,
  NivelHierarquico,
  StatusReuniaoEnum,
  StatusValidacaoEnum,
  TipoConsultor,
} from './enums';

export interface IAuth {
  id?: number;
  nome?: string;
  email?: string;
  role?: string;
  token?: string;
  grupo?: IGrupo;
  modulos?: IModulos[];
  administrador?: boolean;
}

export interface IMercadoAlvoAgenteMercadoRequest {
  idMercadoAlvo: number;
  idUsuario: number;
}

export interface IMercadoAlvoAreaRequest {
  idMercadoAlvo: number;
  idArea: number;
}

export interface IMercadoAlvoIdiomasRequest {
  idMercadoAlvo: number;
  idIdioma: number;
}

export interface IMercadoAlvoNivelCargoRequest {
  idMercadoAlvo: number;
  idNivelCargo: number;
}

export interface IMercadoAlvoSegmentoRequest {
  idMercadoAlvo: number;
  idSegmento: number;
}

export interface IMercadoAlvoSetorRequest {
  idMercadoAlvo: number;
  idSetor: number;
}

export interface IMercadoAlvoSubAreaRequest {
  idMercadoAlvo: number;
  idSubArea: number;
}

export enum TipoRegiao {
  DISPONIBILIDADE = 'DISPONIBILIDADE',
  RESTRICAO = 'RESTRICAO',
}

export interface IMercadoAlvoRegiao {
  id?: number;
  idMercadoAlvo: number;
  pais: string;
  estado: string;
  cidade: string;
  tipo: TipoRegiao;
}

export interface IMercadoAlvo {
  id: number;
  contatoId?: number;

  mercadoAlvoAreas?: IMercadoAlvoAreaRequest[];
  idMercadoAlvoAreas?: number[];

  mercadoAlvoSubAreas?: IMercadoAlvoSubAreaRequest[];
  idMercadoAlvoSubAreas?: number[];

  mercadoAlvoSegmentos?: IMercadoAlvoSegmentoRequest[];
  idMercadoAlvoSegmentos?: number[];

  mercadoAlvoSetores?: IMercadoAlvoSetorRequest[];
  idMercadoAlvoSetores?: number[];

  mercadoAlvoNiveisCargos?: IMercadoAlvoNivelCargoRequest[];
  idMercadoAlvoNiveisCargos?: number[];

  mercadoAlvoAgentesMercados?: IMercadoAlvoAgenteMercadoRequest[];
  idMercadoAlvoAgentesMercados?: number[];

  palavraChave?: string;
  pretensaoSalarioId?: number;
  ultimoSalarioId?: number;

  mercadoAlvoIdiomas?: IMercadoAlvoIdiomasRequest[];
  idMercadoAlvoIdiomas?: number[];

  DisponivelMudancas?: any[];
  idDisponivelMudancas?: number[];
  modeloTrabalho?: string;

  mercadoAlvoRegioes?: IMercadoAlvoRegiao[];
}

export interface IMiniBioDto {
  plano?: string;
  dtInicio?: string;
  dtTermino?: string;
  status?: string;
  etapa?: string;

  nomeCompleto?: string;
  cpf?: string;
  email?: string;
  dataNascimento?: string;
  sexo?: string;
  estadoCivil?: string;

  ultimoCargo?: string;
  ultimoSalario?: string;
  salarioPretendido?: string;

  linkedIn?: string;
  modeloPresencial?: string;
  modeloContratação?: string;

  restricaoAAlgumaEmpresa?: string;
  qualEmpresa?: string;

  restricaoAAlgumSegmento?: string;
  qualSegmento?: string;
}

export interface IEndereco {
  id?: number;
  idContato?: number;
  idTipo?: number;
  cep?: string;
  endereco?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  pais?: string;
  principal?: string;
  ativo?: string;
}

export interface IComandaEtapaReunioes {
  ativo?: boolean;
  id?: number;
  tbEtapaId?: number;
  comandaId?: number;
  dtPrevista?: Date | Dayjs | undefined;
  dtRealizadaInicio?: Date | Dayjs | string | null;
  dtRealizadaFim?: Date | Dayjs | string | null;
  etapaExecutivo?: boolean;
  onboarding?: boolean;
  idConsultorResponsavel?: number;
  idUsuarioCadastro?: number;
  dtCadastro?: string;
  idUsuarioAlteracao?: number;
  valorHora?: number | undefined;
  tempoTotal?: string;
  valorTotal?: string;
  status?: StatusReuniaoEnum;
  statusValidacao?: StatusValidacaoEnum;
}

export interface IReuniao {
  id?: number;
  tbEtapaId?: number;
  comandaId?: number;
  dtPrevista?: Date;
  dtRealizadaInicio?: Date;
  dtRealizadaFim?: Date;
  etapaExecutivo?: boolean;
  onboarding?: boolean;
  idConsultorResponsavel?: number;
  idUsuarioCadastro?: number;
  dtCadastro?: string;
  idUsuarioAlteracao?: number;
  valorHora?: number | undefined;
  tempoTotal?: string;
  valorTotal?: string;
}

export interface IReuniaoInterna extends IdDescricao {
  idConsultor: number;
  idUsuario: number;
  dtPrevista?: Date;
  dtRealizadaInicio?: Date;
  dtRealizadaFim?: Date;
  valorHora?: number;
  status: StatusReuniaoEnum;
  statusValidacao?: StatusValidacaoEnum;
  consultor?: IConsultor;
  usuario?: IUsuario;
  bu: TipoConsultor;
  modeloComercial: ModeloComercial;
}

export interface IOnboarding {
  id?: number;
  nomeConsultor?: string;
  dataRealizada?: string;
  hora?: string;
  consultor?: string;
}

export interface ITelefone extends IdNome {
  idContato?: number;
  idTipo?: number;
  ddd?: string;
  telefone?: string;
  ramal?: string;
  principal?: boolean;
}

export interface INivelFluencia extends IdNome {
  ordem?: number;
}

export interface ICv extends IdNome {
  id?: number;
  idContato?: number;
  idIdioma?: number;
  resumo?: string;
  idioma?: string;
  objetivo?: string;
  cartaApres?: string;
  infoAdicional?: string;
  anexo?: any;
  versao?: number;
  tipo?: number;
  excluido?: number;
  arquivo?: number;
  nomeCv?: string;
  nome?: string;
  dtCadastro?: string;
  ativo?: boolean;
}

export interface IAnexo {
  id?: number;
  idContato?: number;
  arquivo?: string;
  arquivoFile?: any;
  arquivoLink?: string;
  dtCadastro?: Date;
}

export interface IGraduacao {
  id?: number;
  tipo?: string;
  nome?: string;
  programa?: string;
  graduado?: boolean;
  mesGraduacao?: string;
  anoGraduacao?: number;
  descricao?: string;
  IdContato?: number;
}

export interface IHistorico {
  id?: string;
  empresa?: string;
  dataInicio?: string;
  dataFim?: string;
  atual?: string;
}

export interface IHistoricoAcoes {
  id?: number;
  idTipo?: number;
  idComanda?: number;
  dtPrevista?: Date | Dayjs;
  dtCadastro?: Date | Dayjs;
  observacoes?: string;
  anexo?: any;
  realizada?: boolean;
  habilitarEmpresas?: boolean;
  habilitarAssessorado?: boolean;
  notificarCex?: boolean;
  notificarMesa?: boolean;
  notificarPool?: boolean;
  ativo?: boolean;
}

export interface IAcoes extends IdNome {}

export interface ISecao extends IdNome {
  idArea?: number;
  descricao?: string;
  ordem?: string;
  visivel: boolean;
}

export interface ITbArea extends IdNome {
  descricao?: string;
  ordem?: number;
  idParent?: number;
  visivel: boolean;
  ativo?: boolean;
}

export interface ITipoEvento extends IdNome {}

export enum ModoEventoEnum {
  Online = 'Online',
  Presencial = 'Presencial',
}

export interface IEventoBu {
  eventoId?: number;
  bu?: TipoConsultor;
}

export interface IEvento extends IdDescricao {
  id: number;
  titulo: string;
  visivel: boolean;
  palestrante?: string;
  idUnidade?: number;
  dtInicio?: Date;
  dtFim?: Date;
  idTipoEvento?: number;
  idUsuario: number;
  ativo: boolean;
  modoEvento: ModoEventoEnum;
  limiteParticipante?: number;
  linkEvento: string;
  eventoBUs: IEventoBu[];
}

export interface IEventoParticipante {
  id: number;
  idEvento: number;
  idContato?: number;
  idUsuario?: number;
  nome?: string;
  email?: string;
  telefone?: string;
  dtCadastro?: Date;
  confirmado?: boolean;
  compareceu?: boolean;
}

export interface IConteudo {
  id?: number;
  idAreas: [];
  areas: [];
  idSecaos: [];
  secaos: [];
  anexo?: any;
  anexosFile?: any;
  titulo?: string;
  resumo?: string;
  texto?: string;
  origem?: string;
  bu?: string;
  dtPublicacao?: Date;
  ordem?: number;
  visivel: boolean;
  ativo?: boolean;
  tbAnexosConteudo?: any[];
}

export interface UploadAnexoRequest{
  anexoFile?: any;
}


export interface IModulos {
  role?: string;
  modulo?: string;
}

export interface IContato extends IdNome {
  email?: string;
  cpf?: string;
  telefone?: string;
  ultimoCargo?: string;
  ultimaRemuneracao?: string;
  salarioPretendido?: number;
  dtNascimento?: Date | Dayjs;
  sexo?: string;
  estadoCivil?: IEstadoCivil;
  idEstadoCivil?: number;
  linkedIn?: string;
  enderecos?: IEndereco[];
  telefones?: ITelefone[];
  idDaynamics?: string;
  tbContatoResumes?: tbContatoResume[];
}

export interface tbContatoResume {
  id: number;
  idContato: number;
  idIdioma: number;
  resumo: string | null;
  idioma: string | null;
  objetivo: string | null;
  cartaApres: string | null;
  infoAdicional: string | null;
  anexo: string;
  versao: number;
  tipo: string | null;
  excluido: boolean | null;
  arquivo: string | null;
  nomeCv: string;
  nome: string | null;
  ativo: boolean;
}

export interface IEstadoCivil extends IdNome {}

export interface IComandaUsuarioEmpresa {
  id?: number;
  comandaId?: number;
  usuarioEmpresaId?: number;
}

export interface IComanda {
  id?: number;
  modeloComercial?: string;
  bus?: TipoConsultor;
  bUs?: TipoConsultor;
  projetoConfidencial: boolean;

  contatos: IContato[];
  contato?: IContato;
  tbEmpresaId?: number;
  comandaUsuarioEmpresas?: IComandaUsuarioEmpresa[];
  plano?: string;
  etapas?: IEtapa[];
  comandaEtapaReunioes?: IComandaEtapaReunioes[];

  idConsultorOnboarding?: number;
  idConsultorMaster?: number;
  dataPrevista?: Date;
  dataRealizadaInicio?: Date;
  dataRealizadaFim?: Date;
  valorHora: number | undefined;

  historicoAcoes?: IHistoricoAcoes[];
  dataInicio?: string;
  dataFim?: string;
  dataExtensao?: string | null;
  status?: number;
  idStatus?: number;

  comercialResponsavel?: string;
  empresaCliente?: string;
  rhRepresentante?: string;
  estimativaAdesao?: string;

  valorContrato: number;
  taxaSucesso: string;
  recolocadoIndependenteForma?: boolean;
  recolocadoStato?: boolean;
  contatoRelatorio?: string;

  ultimaEmpresaTrabalho?: string;
  regiaoMoradia?: string;

  modeloTrabalho: string;
  restricaoEmpresa: boolean;
  empresaDaRestricao?: string;
  restricaoSegmento: boolean;
  segmentoDaRestricao?: string;
  modeloContrato?: string;

  reuniaoParceiros: boolean;
  relacionamentoMercado: boolean;
  coachingIntegracao: boolean;

  planejamento?: boolean;
  coachingIntegração?: boolean;

  sac?: boolean;
  reclamante?: boolean;
  ra?: boolean;
}

export interface ICRM extends IdNome {
  empresa: string;
  nome: string;
  cpf: string;
  emailPrincipal: string;
  depto: string;
  nívelPosição: string;
  escopoDeResponsabilidade: string;
  modeloComercial: string;
  bUs: string;
  dataInicio: Date;
  dataFim: Date;
  plano: string;
  idConsultorMaster: number;
}

export interface IEtapa extends IdNome {
  texto?: string;
  idPrazo?: string;
  ordem?: number;
  idUsuario?: string;
  imagem?: string;
  idMetodologia?: string;
  objetoForms?: string;
  idMetodologiaNavigation?: IMetodologia;
}

export interface IStatusPrograma extends IdNome {
  idCadastro: string;
  dtCadastro: string;
}

export interface IStatus extends IdNome {}

export interface IModulo {
  privilegio: string;
  nome: string;
  icon: string;
  mostraFilhos: boolean;
  link: string;
  linkHome?: string;
  filhos?: IModulo[];
}

export interface IGrupo {
  id?: number;
  nome: string;
  ativo: boolean;
  perfil?: number;
  area?: IArea[];
}

export interface IArea {
  id: number;
  nome: string;
  ativo: boolean;
}

export interface IAreaPosicao {
  id: number;
  idParent?: number;
  nome: string;
  ativo: boolean;
}

export interface IPerfil {
  id: number;
  nome: string;
  ativo: boolean;
}

export interface IAnalisePerfil {
  id: number;
  contatoId: number;
  informacaoGeralP1?: string;
  informacaoGeralP2?: string;
  idFaixaRemuneracao?: number;
  informacaoGeralP3?: string;
  informacaoGeralP4?: string;
  informacaoGeralP5?: string;
  informacaoGeralP6?: string;
  informacaoGeralP7?: string;
  informacaoGeralP8?: string;
  informacaoGeralP9?: string;
  informacaoGeralP10?: string;
  informacaoGeralP11?: string;
  informacaoGeralP12?: string;
  referencia?: string;
  resultadosRealizacoesCompetencias?: any[];
  analiseSwotForteP1?: string;
  analiseSwotForteP2?: string;
  analiseSwotForteP3?: string;
  analiseSwotForteP4?: string;
  analiseSwotForteP5?: string;
  analiseSwotForteP6?: string;
  analiseSwotForteP7?: string;
  analiseSwotFracoP1?: string;
  analiseSwotFracoP2?: string;
  analiseSwotFracoP3?: string;
  analiseSwotFracoP4?: string;
  analiseSwotFracoP5?: string;
  analiseSwotFracoP6?: string;
  analiseSwotFracoP7?: string;
  analiseSwotFracoP8?: string;
  analiseSwotOportunidadeP1?: string;
  analiseSwotOportunidadeP2?: string;
  analiseSwotOportunidadeP3?: string;
  analiseSwotOportunidadeP4?: string;
  analiseSwotOportunidadeP5?: string;
  analiseSwotAmeacaP1?: string;
  analiseSwotAmeacaP2?: string;
  analiseSwotAmeacaP3?: string;
  analiseSwotAmeacaP4?: string;
  analiseSwotAmeacaP5?: string;
  analiseSwotAmeacaP6?: string;
  analiseSwotForcaOrigem?: string;
  analiseSwotOportunidadeOrigem?: string;
  analiseSwotFraquezaOrigem?: string;
  analiseSwotAmeacaOrigem?: string;
  reflexaoDeCarreiraP1?: string;
  reflexaoDeCarreiraP2?: string;
  reflexaoDeCarreiraP3?: string;
  reflexaoDeCarreiraP4?: string;
  reflexaoDeCarreiraP5?: string;
  reflexaoDeCarreiraP6?: string;
  reflexaoDeCarreiraP7?: string;
  reflexaoDeCarreiraP8?: string;
  reflexaoDeCarreiraP9?: string;
  estiloDeLiderancaP1?: string;
  estiloDeLiderancaP2?: string;
  estiloDeLiderancaP3?: string;
}

export interface ITbFaixaSalario {
  id: number;
  nome?: string;
  ativo?: boolean;
}

export interface IResultadoRealizacaoCompetencia {
  id?: number;
  IdContatoPerfilPlanejamento?: number;
  problemaDesafio?: string;
  acao?: string;
  resultado?: string;
  sintese?: string;
  tbCompetencias?: ICompetencia[];
  idCompetencias?: number[];
  ativo?: boolean;
}

export interface ICompetencia {
  id: number;
  descricao?: string;
  ativo?: boolean;
}

export interface IUnidade {
  id: number;
  nome: string;
  ativo: boolean;
}

export interface IPermissaoModulo {
  id?: number;
  tbUsuarioId?: number;
  tbModuloId: number;
  role: string;
}

export interface IPermissao extends IdDescricao {}

export interface IModulos extends IdDescricao {}

export interface IConsultorTipo {
  id: number;
  tipo: TipoConsultor;
  consultorId: number;
  valorHoraB2C?: number;
  valorHoraB2B?: number;
}

export interface IConsultor {
  id: number;
  nome: string;
  email: string;
  tipoConsultorB2C?: boolean;
  tipoConsultorB2B?: boolean;
  celular?: string;
  foto?: string;
  linkedin?: string;
  nivelHierarquico?: NivelHierarquico;
  consultorTipos?: IConsultorTipo[];
  ativo: boolean;
  senha?: string;
  confirmarSenha?: string;
}

export interface IUsuario {
  id: number;
  nome: string;
  email: string;
  senha: string;
  telefone?: string;
  celular?: string;
  cargo?: string;
  valorHoraB2C?: number;
  valorHoraB2B?: number;
  tipoConsultorB2C?: boolean;
  tipoConsultorB2B?: boolean;
  ativo: boolean;
  modeloComercial: string;
  nivelHierarquico?: NivelHierarquico;
  idUnidade?: number;
  confirmarSenha?: string;
  idSegmentoPrincipal?: number;
  idSegmentoSecundario?: number;
  consultor?: boolean;
  unidade?: IUnidade;
  permissoes?: IPermissaoModulo[];
  administrador: boolean;

  perfilPublico?: number;
  foto?: string;
  biografia?: string;
  linkedin?: string;
  idGrupo?: number;
  idArea?: number;
}

export interface IdDescricao {
  id?: number;
  descricao?: string;
  ativo?: boolean;
}

export interface IdNome {
  id?: number;
  nome?: string;
  ativo?: boolean;
}

export interface IConfigArea extends IdDescricao {
  idSubAreas: IConfigSubArea[];
  subAreas?: number[];
}

export interface IConfigSubArea extends IdDescricao {}

export interface IPrazo extends IdNome {
  tempo?: number;
}

export interface IPlanosTemplate extends IdNome {
  etapas: IEtapa[];
  periodoEmMeses?: number;
}

export interface IMetodologia extends IdNome {
  texto?: string;
}

export interface IFaixaSalarial extends IdNome {}

export interface IConfigSegmento extends IdDescricao {}

export interface INivelDeCargo extends IdDescricao {}

export interface ITipoDeAcao extends IdNome {}

export interface IIdioma extends IdNome {
  grauDeFluencia?: string;
}

export interface IIdiomaContato {
  id?: number;
  idNivelFluencia?: number;
  idContato?: number;
  idIdioma?: number;
}

export interface IPlano {
  id: number;
  nome: string;
  ativo?: boolean;
}

export interface IResponsavel {
  id?: number;
  descricao: string;
  ativo: boolean;
}

export interface IStatus {
  id: number;
  nome: string;
  ativo: boolean;
}

export interface IGrupoTrabalho {
  id: number;
  nome: string;
  ativo: boolean;
}

export interface IPrograma {
  idGrupoTrabalho: number;
  idPlano: number;

  ContatoApos?: string;
  IsShopping?: string;
  DtShopping?: string;
  IdStatusPrograma?: string;
  nome?: string;
  NomeParaCliente?: string;
  IdEmpresaCliente?: string;
  IdContatoClienteReport?: string;
  IdContatoClienteBilling?: string;
  IdContatoClienteRh?: string;
  DataExpiracao?: string;
  DataProrrogada?: string;
  AvisoInicioObrigatorio?: string;
  RelatorioProgresso?: string;
  RelatorioConsolidado?: string;
  RelatorioIndividual?: string;
  IdPrazoFrequenciaRelatorio?: string;
  UseridResponsavelRelatorio?: string;
  IdUsuarioCadastro?: string;
  IdUsuarioAtualizacao?: string;
  IdGrupoTrabalho?: string;
  IdUnidade?: string;
  IdTipoRecolocacao?: string;
  DtRecolocacao?: string;
  ImportadoMac?: string;
  Teste?: string;
  IdEmpresaAtual?: string;
  IdFaturamento?: string;
  BoCompleto?: string;
  FaturamentoValor?: string;
  StatusProgramaJustificativa?: string;
  ativo?: boolean;
}

export interface ISetor extends IdDescricao {
  idSegmento?: number;
}

export interface IDisponibilidadeDeMudança extends IdDescricao {
  sigla: string;
}

export interface IEmpresa {
  id?: number;
  cnpj: string;
  razaosocial: string;
  nomefantasia?: string;
  matriz?: boolean;
  ativo?: boolean;
}

export interface IUsuarioEmpresa extends IdNome {
  empresaId: number;
  sobrenome: string;
  cargo: string;
  email: string;
  senha?: string;
  confirmarSenha?: string;
}

export interface IContatoHistoricoEmpresa {
  id?: number;
  contatoId?: number;
  empresaId?: number;
  posicoes?: IContatoHistoricoEmpresaPosicao[];
  empresaIdNavigation?: IEmpresa;
}

export interface IContatoHistoricoEmpresaPosicao {
  id?: number;
  historicoEmpresaId?: number;
  areaId?: number;
  nivelId?: number;
  escopoId?: number;
  cargo: string;
  cargoAtual: boolean;
  dataInicio?: Date | Dayjs | null;
  dataTermino?: Date | Dayjs | null;
  superior: string;
  numeroDeSubordinados: number;
  responsabilidades: string;
  realizacoes: string;
  motivoSaida: string;
}

export interface IEscopoResonsabilidade extends IdNome {}

export interface INivelPosicao extends IdNome {}
