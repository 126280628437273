import { Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { TextFieldStato } from '../../../../commons/styleds';
import InputArquivoMethods from '../../../../components/InputArquivo/InputArquivoMethods';

interface Props {
    editAcao: boolean;
    setArquivoUrl: any;
    arquivoUrl: any;
    setNomeArquivo: any;
    nomeArquivo: any;

    setBannerUrl: any;
    bannerUrl: any;
    setNomeArquivoBanner: any;
    nomeArquivoBanner: any;
    anexosExtras: any[];
}

const ConteudoStepper3: React.FC<Props> = (props: Props) => {
    const { editAcao, arquivoUrl, bannerUrl, anexosExtras } = props;
    const methods = useFormContext();
    const editMethods = useFormContext();

    return (
        <>
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                {!props.nomeArquivoBanner ? (
                    <InputArquivoMethods
                        isEdit={editAcao}
                        objOnEdit={editMethods}
                        setObjOnEdit={editMethods}
                        newObj={methods}
                        setNewObj={methods}
                        nameFile={props.nomeArquivoBanner}
                        setNameFile={props.setNomeArquivoBanner}
                        urlFile={bannerUrl!}
                        setUrlFile={props.setBannerUrl}
                        propriedade={'anexo'}
                        title='Banner da Postagem'
                        acceptTypes={{
                            'image/jpeg': ['jpg', 'jpeg'],
                            'image/png': ['png'],
                            'image/webp': ['webp']
                        }}
                    />
                ) : (
                    <>
                        <Grid
                            container
                            xs={2}
                            md={2}
                            sx={{ display: 'flex', padding: '20px 10px' }}
                        >
                            <img
                                src={props.nomeArquivoBanner}
                                alt="imagem"
                                width={'100%'}
                                style={{ maxWidth: '200px' }}
                            />
                        </Grid>
                    </>
                )}

                <InputArquivoMethods
                    isEdit={editAcao}
                    objOnEdit={editMethods}
                    setObjOnEdit={editMethods}
                    newObj={methods}
                    setNewObj={methods}
                    nameFile={props.nomeArquivo}
                    setNameFile={props.setNomeArquivo}
                    urlFile={arquivoUrl!}
                    setUrlFile={props.setArquivoUrl}
                    propriedade={'anexosFile'}
                    title='Anexos extra da Postagem'
                />

                <Grid item xs={12} md={12} sm={12}>
                    {anexosExtras && anexosExtras.length ? (
                        <>
                            {anexosExtras.map((c: any, i: number) => (
                                <Typography
                                    key={i}
                                    sx={{ padding: '10px', display: 'block' }}
                                    variant="caption"
                                >
                                    <a href={c.anexo} target="_blank" rel="noopener noreferrer">
                                        {i + 1}º - Anexo
                                    </a>
                                </Typography>
                            ))}
                        </>
                    ) : null}
                </Grid>

                <Grid item xs={12} md={4} sm={4}>
                    <Controller
                        control={methods.control}
                        name='ativo'
                        render={({ field }) => (
                            <FormControlLabelStato
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    //defaultChecked={true}
                                    />
                                }
                                label='Ativo'
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={8} sm={8}>
                    <Controller
                        control={methods.control}
                        name='visivel'
                        render={({ field }) => (
                            <FormControlLabelStato
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    //defaultChecked={true}
                                    />
                                }
                                label='Visivel Portal Assessorado'
                            />
                        )}
                    />
                </Grid>

            </Grid>
        </>
    );
};


const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default ConteudoStepper3;
