import { IConteudo, UploadAnexoRequest } from '../../../commons/genericTypes';
import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';
import { conteudoAtom } from './Conteudo';


function useConteudoActions() {
  const baseActions = useBaseActions('Conteudo', conteudoAtom);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/Conteudo`;
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
    createFormData: baseActions.createFormData,
    uploadAnexo: uploadAnexoFormData,
    updateNew: updateNew
  };

  async function uploadAnexoFormData(obj: object | any) {
    const payload: UploadAnexoRequest = {
      anexoFile: obj
    }

    const recurso = await axiosWrapper.postFormData(`${baseUrl}/upload-anexo`, payload);
    return recurso;
  }

  async function updateNew(id: number, obj: object | any) {
    const recurso = await axiosWrapper.putFormData(`${baseUrl}/update/${id}`, obj);
    return recurso;
  }
}

export { useConteudoActions };
